var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sub-head', _vm._b({
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('u-tit-default', _vm._b({
          staticClass: "white--text line-height-1 tit--xxl",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "200"
          }
        }, 'u-tit-default', _vm.$attrs, false), [_vm._t("title")], 2)];
      },
      proxy: true
    }], null, true)
  }, 'sub-head', Object.assign({
    subVisualAttrs: _vm.subVisualAttrs,
    tabsTag: _vm.tabsTag,
    tabsAttrs: _vm.tabsAttrs,
    tabTag: _vm.tabTag,
    tabAttrs: _vm.tabAttrs
  }, _vm.$attrs), false));

}
var staticRenderFns = []

export { render, staticRenderFns }