<template>
    <u-tit-wrap-default v-bind="$attrs">
        <u-tit-dot data-aos="fade-up" data-aos-delay="200" :class="titClass" class="text-uppercase"><slot /></u-tit-dot>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDot from "@/components/publish/styles/typography/u-tit-dot.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {        
        titClass: { type: String, default: "" },
    },
    components: {
        UTitWrapDefault,
        UTitDot,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
