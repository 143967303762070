<template>
    <u-sub-head-basic v-bind="$attrs" tabsTag="u-tabs-slider" tabTag="v-tab" :tabsAttrs="{ grow: false, class: 'v-tabs--wide-border' }">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-sub-head-basic>
</template>

<script>
import USubHeadBasic from "@/sets/styles/pages/u-sub-head-basic.vue";

export default {
    props: {},
    components: {
        USubHeadBasic,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){    
    ::v-deep {
        .v-tab {
            &:hover {
                color: var(--tab-active-color) !important;
            }
        }
    }
}
@media (min-width:1200px){
}
</style>
