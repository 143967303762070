<template>
    <sub-head v-bind="{ subVisualAttrs, tabsTag, tabsAttrs, tabTag, tabAttrs, ...$attrs }">
        <template #title>
            <u-tit-default v-bind="$attrs" data-aos="fade-up" data-aos-delay="200" class="white--text line-height-1 tit--xxl"><slot name="title" /></u-tit-default>
        </template>
    </sub-head>
</template>

<script>
import SubHead from "@/sets/styles/pages/sub-head.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";

export default {
    props: {
        subVisualAttrs: { type: Object, default: () => ({}) },
        tabsTag: String,
        tabsAttrs: { type: Object, default: () => ({}) },
        tabTag: String,
        tabAttrs: { type: Object, default: () => ({}) },
    },
    components: {
        SubHead,
        UTitDefault,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped></style>
