<template>
    <u-page-section-basic v-bind="$attrs">
        <template #pageSectionHead><slot name="pageSectionHead" /></template>

        <tit-wrap-primary v-if="this.$slots['tit']" :titClass="titClass">
            <slot name="tit" />
        </tit-wrap-primary>
        <slot />

        <template #pageSectionFoot><slot name="pageSectionFoot" /></template>
    </u-page-section-basic>
</template>

<script>
import UPageSectionBasic from "@/sets/styles/pages/u-page-section-basic.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        titClass: { type: String, default: "" },
    },
    components: {
        UPageSectionBasic,
        TitWrapPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
