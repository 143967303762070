var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-head"
  }, [_c('div', _vm._b({
    staticClass: "sub-visual",
    class: _vm.subVisualAttrs.class
  }, 'div', {
    subVisualAttrs: _vm.subVisualAttrs
  }, false), [_c('div', {
    staticClass: "sub-visual__inner"
  }, [this.$slots['title'] ? _c('v-container', [_vm._t("title")], 2) : _vm._e()], 1), _c('div', {
    staticClass: "sub-visual__bg",
    style: 'background-image:url(' + _vm.bg + ');'
  })]), _c('gnb', _vm._b({
    attrs: {
      "gnbName": "sub-tab"
    }
  }, 'gnb', {
    itemTitle: _vm.itemTitle,
    tabsTag: _vm.tabsTag,
    tabsAttrs: _vm.tabsAttrs,
    tabTag: _vm.tabTag,
    tabAttrs: _vm.tabAttrs,
    tabActive: _vm.tabActive
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }